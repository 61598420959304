import React, { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import routes from 'src/routes';
import { UserTypes } from 'src/constants/enums';
import s from './Home.module.css';
import manImg from '../../assets/img/main-page-man.svg';
import { ModalsTypes } from '../../types/popUp';
import useActions from '../../hooks/useActions';
import useTypedSelector from '../../hooks/useTypedSelector';

const Home: React.FC = () => {
  const { isAuth, isActive } = useTypedSelector((state) => state.auth);
  const { isProvider, type } = useTypedSelector((state) => state.userProfile);
  const { setCurrentModal } = useActions();
  useEffect(() => {
    if (isActive
      && type
      && ![UserTypes.UndefinedBranch, UserTypes.UndefinedEmployee].includes(type)
    ) {
      setCurrentModal(ModalsTypes.null);
    }
  }, []);

  return (
    <section className={s.page}>
      <section className={`${s.section_1} ${s.section}`}>
        <div className={s.s1_left}>
          <div className={s.s1_title}>
            <p>Dent</p>
            <p>MarketPlace</p>
          </div>
          <div className={s.s1_options}>
            <div className={s.s1_option}>
              Агрегатор стоматологических товаров
            </div>
            <div className={s.s1_option}>
              - Удобно, выгодно, просто
            </div>
            <div className={s.s1_option}>
              - Самый разумный анализ поставщиков по нашему мнению
            </div>
            {isAuth && (
              <NavLink className={s.link} to={routes.market}>
                Посмотреть витрину
              </NavLink>
            )}
            {!isAuth && (
              <button
                type="button"
                className={s.link}
                onClick={() => setCurrentModal(ModalsTypes.login)}
              >
                Посмотреть витрину
              </button>
            )}
          </div>
        </div>
        <div className={s.s1_right}>
          <div className={s.s1_img}>
            <img src={manImg} alt="manImg" />
          </div>
        </div>
      </section>
      <section className={`${s.section_2} ${s.section}`}>
        <div className={s.s2_title}>
          <span>С чего начать?</span>
        </div>
        <div className={s.s2_subtitle}>
          Чтобы начать пользоваться сервисом достаточно просто создать заявку,
          на которую смогут откликнуться поставщики
        </div>

        {!isAuth && (
          <div
            onClick={() => setCurrentModal(ModalsTypes.login)}
            className={s.s2_create_request}
          >
            Создать заявку
          </div>
        )}
        {isAuth && isProvider && (
          <Link to={routes.personal}>
            <div className={s.s2_create_request}>Создать предложение</div>
          </Link>
        )}
        {isAuth && !isProvider && (
          <Link to={routes.new}>
            <div className={s.s2_create_request}>Создать заявку</div>
          </Link>
        )}
        {(!isAuth || !isProvider) && (
          <div className={s.block}>
            <div className={s.s2_title}>
              <span>Инструкция для заказчиков</span>
            </div>
            <div className={s.s2_video}>
              <iframe
                src="https://vk.com/video_ext.php?oid=-228345594&id=456239019"
                width="100%"
                height="100%"
                allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
                frameBorder="0"
                allowFullScreen
                title="Vk video player"
              />
            </div>
          </div>
        )}
        {(!isAuth || isProvider) && (
          <div className={s.block}>
            <div className={s.s2_title}>
              <span>Инструкция для поставщиков</span>
            </div>
            <div className={s.s2_video}>
              <iframe
                src="https://vk.com/video_ext.php?oid=-228345594&id=456239018&hd=1"
                width="100%"
                height="100%"
                allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
                frameBorder="0"
                allowFullScreen
                title="Vk video player"
              />
            </div>
          </div>
        )}
        <div className={s.s2_title}>
          <Link to={routes.termsOfUse}>
            <span>Правила работы на сайте</span>
          </Link>
        </div>
      </section>
    </section>
  );
};

export default Home;
